import { Content } from "@/ui";
import { simple_pages_helper } from "@/helpers/simple_pages_helper";
import { createTitle } from "@/functions/createTitle";
import { getGlobal } from "@/functions/getGlobal";
import { getRelatedJobs } from "@/functions/getRelatedJobs";
import { getNestedRoutes } from "@/functions/getNestedRoutes";
import sn from "@/snippets";

export default function Page({ content }) {
  return (
    <>
      <Content items={content} />
    </>
  );
}

export async function getStaticProps({ params: { url_slugs } }) {
  const pages = getNestedRoutes({ url_slugs, overwrites: { "find-a-job": { href: "/jobs" } } });
  const [_page, prevPage] = [...pages].reverse();
  const page = simple_pages_helper.nestedFind(url_slugs);
  const jobs = getRelatedJobs({ page });

  if (!page) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      meta: {
        title: createTitle(...pages.map((i) => i.label).reverse()),
      },
      content: [
        {
          component: "BreadcrumbNavigation",
          props: {
            items: pages,
          },
        },
        sn.header({ page, prevPage }),
        Array.isArray(page.carousel_items) && {
          component: "LogoCarousel",
          id: "LogoCarousel",
          props: {
            title: page.carousel_title ?? null,
            items: (page.carousel_items ?? []).map((i) => ({
              title: i["title"] ?? null,
              img: i["image"] ?? null,
              href: i["link"] ?? "#",
            })),
            visibleCount: page.carousel_items_per_slide ?? 1,
          },
        },
        {
          component: "RichText",
          id: "Intro",
          props: {
            className: "bg-light text-dark py-4 py-md-5",
            body: page.intro ?? null,
          },
        },
        {
          component: `${page.url_slug == "hire-staff-outside-the-uk" && "LeadooBot"}`,
        },
        Array.isArray(page.grid_buttons) && {
          component: "GridButtonsGrid",
          id: "GridButtonsGrid",
          props: {
            title: page.grid_buttons_title ?? null,
            items: page.grid_buttons.map((i) => ({
              title: i["title"] ?? null,
              img: i["image"] ?? null,
              href: i["link"] ?? "#",
            })),
          },
        },

        // START - New Components
        sn.features({ page }),
        sn.featureTabs({ page }),
        sn.benefits({ page }),
        sn.trendingJobs({ page }),
        sn.tileGroups({ page }),
        sn.promotionalCarousel({ page }),
        sn.multiTiles({ page }),
        sn.multiCards({ page }),
        sn.statCounterWidget({ page }),
        // END - New Components

        Array.isArray(page.collapsible_section_items) && {
          component: "CollapsibleSection",
          props: {
            title: page.collapsible_section_title ?? null,
            description: page.collapsible_section_description ?? null,
            items: page.collapsible_section_items.map((i) => ({
              title: i["title"] ?? null,
              body: i["body"] ?? null,
            })),
          },
        },
        sn.body({ page, url_slugs }),
        ...(Array.isArray(page.narrative_panels)
          ? page.narrative_panels.map((i, k) => ({
              component: "NarrativePanel",
              id: `NarrativePanel-${k}`,
              props: {
                className: `py-4 py-md-5 bg-${i["background_color"] ?? "white"} text-${i["text_color"] ?? "dark"}`,
                title: i["title"] ?? null,
                description: i["description"] ?? null,
                img: i["image"] ?? null,
                embed_video_link: i["embed_video_link"] ?? null,
                // cta: i["button_link"]
                //   ? {
                //       label: i["button_label"] ?? "Read more",
                //       href: i["button_link"] ?? "#",
                //       variant: i["button_variant"] ?? "secondary",
                //     }
                //   : null,
                reverse: i["reverse"] === "true" || i["reverse"] === true,
              },
            }))
          : []),
        sn.chatbot({ page }),
        sn.promoSection({ page: { ...page, ..._page } }),
        ...(Array.isArray(page.form)
          ? page.form.map((i, k) => ({
              component: "Form",
              id: `Form-${k}`,
              props: {
                className: k % 2 === 0 ? "py-4 py-md-5 bg-light" : "py-4 py-md-5 bg-white",
                title: i["title"] ?? null,
                description: i["description"] ?? null,
                formId: i["form_id"] ?? null,
              },
            }))
          : []),
        ...(jobs && jobs.length > 0
          ? [{ component: "LatestJobs", props: { title: page.related_jobs_title ?? null, items: jobs } }]
          : []),
      ],
    },
  };
}

export async function getStaticPaths() {
  const global = getGlobal();

  return {
    paths: simple_pages_helper.toNestedPaths({
      exclude: global["_theme.simplePagesExcludeList"].split(",").map((i) => i.trim()),
    }),
    fallback: true,
  };
}
